const config = {
  VERSION: "0.0.1",
  API_ADDRESS: process.env.REACT_APP_STAGE === 'local' 
    ? "http://localhost:3001/forms"
    : "https://formservice.w86dev.click:5000/forms",
  USER_ADDRESS: process.env.REACT_APP_STAGE === 'local'
    ? "http://localhost:4001/client/authz-service"
    : "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/authz-service",
   REGION: "ap-southeast-2",
  USER_POOL_ID: "ap-southeast-2_Kp9DpRrdK",
  APP_CLIENT_ID:"5f93lfrqqh7nruhjiiin8qbeem",
  DOMAIN: "form.w86dev.click",
  ISS: "https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_2f9Awkzdb",
  USAGE_ADDRESS: process.env.REACT_APP_STAGE == '',
  FILE_ADDRESS: "https://file.w86dev.click:9300/file/get",
  PROJECT_SERVICE_URL: process.env.REACT_APP_STAGE == 'dev' ? "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/project-service/project" : "https://srkd6ccl2g.execute-api.ap-southeast-2.amazonaws.com/prod/project-service/project",

  ADMIN_DASHBOARD_URL: "http://localhost:3000",
  AUTOSAVE_INTERVAL: 30,
  ADOBE_CONFIG_API_KEY: "88d8993b747244238c1b0f87c182882c",
  ANNOTATION_SERVICE_PUBLIC_URL: "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/form-annotation/public",
  ANNOTATION_SERVICE_AUTH_URL: "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/form-annotation/graphql/annotation",
  UPLOAD_MEDIA_SIZE:30000000,
  STAGE: process.env.REACT_APP_STAGE || 'dev',
};

export default config;
