import React from "react";

// Material UI
import { AddCommentText, StyledPopover } from "./Util/AnnotationStyledComponents";

function Popover({ children, openSidebar, showPopover, isAnnotationModeOn, openWithoutReplyBox }) {
    return showPopover ? (
        <StyledPopover>
            {children}
        </StyledPopover>
    ) : (
        children
    );
}

export default Popover;
